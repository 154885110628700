import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import MatchHeight from 'matchheight';

const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
})

const Formule = ({ entryId }) => {
    const [productPrice, setProductPrice] = useState(null);
    const [productDescription, setProductDescription] = useState(null);
    const [productImage, setProductImage] = useState(null);
    const [productTitle, setProductTitle] = useState(null);
    const [matchHeight, setMatchheight] = useState(new MatchHeight());

    useEffect(() => {
        const fetchEntry = async () => {
          try {
            const response = await client.getEntry(entryId.sys.id);
    
            // Assuming the content model has fields like title, image, description, etc.
            const { productDescription, productImage, productPrice, productTitle } = response.fields;
            if (productImage) {
              setProductImage(productImage[0].secure_url)
            }
            if (response.fields.productTitle) {
              setProductTitle(response.fields.productTitle)
            }
            if (response.fields.productDescription) {
              setProductDescription(response.fields.productDescription)
            }
            if (productPrice) {
              setProductPrice(productPrice)
            }
          } catch (error) {
            console.error('Error fetching entry:', error);
          }
        };
    
        fetchEntry();
      }, [entryId]);

      useEffect(() => {
        setTimeout(() => {
          console.log('runs');
          matchHeight.update();
        }, 500);
      }, [productPrice,productImage,productTitle,productDescription]);

    return (
      <div className='col-12 col-md-6 col-xl-4'>
        <div className="card">
          <div className="card__image__wrapper" data-mh>
            {productImage !== null && !productImage.includes('video') &&
            <img src={productImage} alt={productTitle} />
            }
            {productImage !== null && productImage.includes('video') &&
              <video autoPlay playsInline controls muted src={productImage}></video>
            }
          </div>
          <div className="card__content">
              <div className="card__content__title" data-mh>
                  <h2>{productTitle}</h2>
              </div>
              <p data-mh>{productDescription}</p>
            <div className="card__content__price text-black">
              {productPrice}
            </div>
            <a className="stretched-link" href="/formule"></a>
          </div>
        </div>
      </div>
    );
};

export default Formule;